import _ from 'lodash';
import { ColumnRuleOutputV1 } from 'sdk/model/ColumnRuleOutputV1';
import { ColumnDefinitionOutputV1, ColumnRuleInputV1 } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import {
  AllColumnEnumOptions,
  CanonicalCategoryOrder,
  ColumnRule,
} from '@/tableDefinitionEditor/columnRules/columnRule.constants';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { ColumnRulesWithMetaData } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.constants';
import { getColumnRuleWithMetaData } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.utilities';
import { ColumnRuleWithMetadata } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';
import { TableDefinitionAccessSettings } from '@/tableDefinitionEditor/tableDefinition.types';
import { t } from 'i18next';
import { sqTableDefinitionStore } from '@/core/core.stores';
import { ReactSelectOption } from '@/core/IconSelect.molecule';

export const getAllowedColumnTypes = (columnRule: ColumnRule): ColumnTypeEnum[] => {
  return getColumnRuleWithMetaData(columnRule)?.allowedOnColumnTypes ?? [];
};

const getRulesAllowedOnColumnType = (columnType?: ColumnTypeEnum): ColumnRuleWithMetadata[] => {
  return columnType
    ? ColumnRulesWithMetaData.filter((columnRule) => columnRule.allowedOnColumnTypes.includes(columnType))
    : ColumnRulesWithMetaData;
};

export const getRulesAllowed = (
  isFirstRuleOnColumn: boolean,
  columnType?: ColumnTypeEnum,
  columnRuleInputToEdit?: ColumnRuleInputV1,
  subscriberId?: string,
): ColumnRuleWithMetadata[] => {
  const allowedOnType = getRulesAllowedOnColumnType(columnType);
  const rulesFilteredByFallback = allowedOnType.filter((columnRule) => columnRule.canBeFallback !== false);
  const allowedRules = isFirstRuleOnColumn ? allowedOnType : rulesFilteredByFallback;
  const columnRuleIsSetItemProperty = !!columnRuleInputToEdit?.setItemProperty;
  const allowedRulesMinusSetProperty = columnRuleIsSetItemProperty
    ? allowedRules
    : allowedRules.filter((columnRule) => columnRule.rule !== SeeqNames.MaterializedTables.Rules.SetItemProperty);
  return !subscriberId
    ? allowedRulesMinusSetProperty
    : allowedRulesMinusSetProperty.filter(
        (columnRule) => columnRule.rule !== SeeqNames.MaterializedTables.Rules.EventProperty,
      );
};

export const getAllowedColumnTypesGivenCurrentColumnAndSetOfRules = (
  ruleInputs: ColumnRuleInputV1[],
  currentColumnTypeIfConfigured?: ColumnTypeEnum,
): ColumnTypeEnum[] => {
  return ruleInputs.reduce((commonTypes, ruleInput) => {
    const rule: ColumnRule = getRuleTypeFromRuleInput(ruleInput);
    let currentList: ColumnTypeEnum[];
    if (
      rule === SeeqNames.MaterializedTables.Rules.Constant.BaseConstant &&
      currentColumnTypeIfConfigured !== undefined
    ) {
      // A constant rule that has been configured on a given column is by necessity of the same type as the
      // column, so we don't want to allow changing the column type if you have a configured constant rule
      currentList = [currentColumnTypeIfConfigured];
    } else {
      currentList = getAllowedColumnTypes(rule);
    }
    return _.compact(commonTypes.filter((type) => currentList.includes(type)));
  }, AllColumnEnumOptions);
};

export const getRuleTypeFromRuleInput = (ruleInput: ColumnRuleInputV1): ColumnRule => {
  // Each rule input should have only one field filled out, so we can just get the first one as the rule type
  return Object.keys(ruleInput)[0] as ColumnRule;
};

export const columnRuleOutputToColumnRuleInput = (
  ruleOutput: ColumnRuleOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
  accessSettings: TableDefinitionAccessSettings,
): ColumnRuleInputV1 | any => {
  // The constant rule is unique in that there is only 1 api input type but multiple api output types
  const isConstantRule = /constant/i.test(ruleOutput.rule);
  const isScalarCreatorRule = /scalarCreator/i.test(ruleOutput.rule);
  let ruleType;
  if (isConstantRule) {
    ruleType = SeeqNames.MaterializedTables.Rules.Constant.BaseConstant;
  } else if (isScalarCreatorRule) {
    ruleType = SeeqNames.MaterializedTables.Rules.ScalarCreator.BaseScalarCreator;
  } else {
    ruleType = ruleOutput.rule;
  }
  if (!isValidColumnRule(ruleType)) {
    throw new Error(`Invalid rule type: ${ruleType}`);
  }

  const columnRuleWithMetadata = getColumnRuleWithMetaData(ruleType);
  if (!columnRuleWithMetadata) {
    throw new Error(`Could not find metadata for rule: ${ruleType}`);
  }

  const result = {
    [ruleType]: columnRuleWithMetadata.columnRuleOutputToColumnRuleInput(ruleOutput, otherColumns, accessSettings),
  };

  if (ruleType === 'formulaCreator') {
    return { ...result, inputs: ruleOutput.inputs };
  }

  return result;
};

const isValidColumnRule = (rule: string): rule is ColumnRule => {
  return ColumnRulesWithMetaData.some((columnRule) => columnRule.rule === rule);
};

const getDefaultColumnNameBase = () => t('SCALING.NEW_COLUMN_NAME_BASE');
export const generateColumnName = (baseName?: string): string => {
  const columns = sqTableDefinitionStore.columns;
  const existingColumnNames = columns
    .map((col) => col.displayName)
    // The following are reserved names and should not be used
    .concat([SeeqNames.MaterializedTables.DatumIdColumn, SeeqNames.MaterializedTables.ItemIdColumn]);
  const base = baseName ?? getDefaultColumnNameBase();
  const existingNames = new Set(existingColumnNames);
  let newName = base;
  let i = 2;
  while (existingNames.has(newName)) {
    newName = `${base} ${i}`;
    i++;
  }
  return newName;
};

const sortGroup = (itemGroup: string) => {
  return CanonicalCategoryOrder.indexOf(itemGroup);
};

export const groupItems = (ungrouped: readonly ReactSelectOption[]) => {
  const groupItem = (item: ReactSelectOption) => {
    return item?.group;
  };

  return _.chain(ungrouped)
    .groupBy(groupItem)
    .sortBy((obj) => sortGroup(obj[0].group!))
    .map((items) => {
      return { label: t(items[0].group!), options: _.sortBy(items, 'text') };
    })
    .value();
};
